.delete-button {
    border: none;
    text-decoration: none;
    display: inline-block;
    font-size: 24px; /* Adjust the size as needed */
    margin: 4px 2px;
    cursor: pointer;
  }
.dWMRNy {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}
.iBEElS {
    height: inherit;
    top: 0px;
}
.iBEElS {
    position: absolute;
    width: 1000px;
    height: inherit;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 50%;
    /*border-top-left-radius: ;
    border-top-right-radius: ;*/
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: var(--ds-surface,#FFFFFF);
    box-shadow: var(--ds-shadow-overlay,0 var(--ds-space-100,8px) 16px -4px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
    z-index: -1;
    transform: translate3d(-50%, 0px, 0px) scale3d(1.1, 1, 1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.bJStMD {
    transition: opacity 300ms ease-out 300ms;
    opacity: 1;
}
.fgfUVu {
    min-height: inherit;
    margin-top: 0px;
}
.fgfUVu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: var(--ds-space-0,0px) auto;
    width: 100%;
    max-width: 1100px;
    min-height: inherit;
}
.ijKeUx {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    height: auto;
}
.ijKeUx {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    height: auto;
}
.bFtVSx {
    position: relative;
}
.fayCzS {
    overflow-wrap: break-word;
}
.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}
.ewysoW {
    font-size: 0.857143em;
    font-style: inherit;
    line-height: 1.33333;
    color: var(--ds-text-subtlest,var(--ds-text-subtlest,#6B778C));
    font-weight: var(--ds-font-weight-semibold,600);
    margin: 0px;
}
.DgkTO {
    color: var(--ds-link,#f37237);
}
.dUxdnV {
    margin-top: 8px;
}

.dUxdnV {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 16px;
}
.iGtjzS {
    position: absolute;
    top: 0px;
    right: 0px;
}
.cLrmQm {
    display: inline-flex;
}
.eteVrT {
    display: inline-flex;
    transition: box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}
.css-q68uj {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
button {
    font-family: inherit;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-q68uj {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
.css-noix33 {
    align-self: center;
    display: flex;
    flex-shrink: 0;
    line-height: 0;
    font-size: 0px;
    user-select: none;
    margin: 0px -2px;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-snhnyn {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    --icon-primary-color: currentColor;
    --icon-secondary-color: var(--ds-surface, #FFFFFF);
}
.css-snhnyn > svg {
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
}
.cXwmnx {
    display: none;
}
.jpQXKX {
    margin-top: 24px;
}
.bxpmxB {
    display: flex;
    margin: -4px -4px 16px;
    flex-wrap: wrap;
}
.bxpmxB > * {
    display: block;
    margin: 4px;
    min-width: 0px;
}
.bxpmxB #request-search-filter > div:first-child {
    min-height: 32px;
    height: 32px;
}
.css-1o07z2d {
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--ds-background-input, #FAFBFC);
    border-color: var(--ds-border-input, #DFE1E6);
    color: var(--ds-text, #091E42);
    cursor: text;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    font-size: 14px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    overflow-wrap: break-word;
    vertical-align: top;
    pointer-events: auto;
}
.css-mfjdp3:placeholder-shown {
    text-overflow: ellipsis;
}

.css-mfjdp3:not([data-compact]) {
    padding: 8px 6px;
    height: 2.57em;
}

.css-mfjdp3 {
    background-color: transparent;
    border: 0px;
    box-sizing: border-box;
    color: inherit;
    cursor: inherit;
    font-size: 14px;
    min-width: 0px;
    outline: none;
    width: 100%;
    line-height: 1.42857;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.css-q68uj {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}

.css-q68uj {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
.css-noix33 {
    align-self: center;
    display: flex;
    flex-shrink: 0;
    line-height: 0;
    font-size: 0px;
    user-select: none;
    margin: 0px -2px;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-snhnyn > svg {
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
}
.gxwVbe {
    display: flex;
}
.bxpmxB > * {
    display: block;
    margin: 4px;
    min-width: 0px;
}
.jnXgrr {
    position: relative;
}
.css-370xbg {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 400;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: #091e420a;   
    color: black;
     cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 10px;
    vertical-align: middle;
    width: auto;
    -webkit-box-pack: center;
    justify-content: center;
    color:var(--ds-text, #42526E) ;
}

.css-370xbg1 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: var(--ds-background-selected, #808080);
    cursor: not-allowed;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 10px;
    vertical-align: middle;
    width: auto;
    -webkit-box-pack: center;
    justify-content: center;
    color: var(--ds-text-selected, #F4F5F7) !important;
}



.css-178ag6o {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    margin: 0px 2px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.iitdmc {
    padding-right: 16px;
}
.hPkIUt {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}
.iHCcjr {
    margin-left: 4px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}
.css-5a7vsu-container {
    pointer-events: all;
    position: relative;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.css-6m1tpa-control {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 3px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 32px;
    position: relative;
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
    box-sizing: border-box;
    font-weight: 500;
    border: 0px;
    height: 32px;
    padding: 4px 2px 4px 4px;
    background-color: var(--ds-background-selected,#253858) !important;
    outline: 0px !important;
    color: var(--ds-text-selected,#FFFFFF) !important;
}
.css-11tnip9 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 2px 6px;
    width: 100%;
}
.css-sf6j83-singleValue {
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(0%);
    box-sizing: border-box;
    line-height: 16px;
    color: var(--ds-text-selected,#FFFFFF) !important;
    opacity: 1;
    transition: opacity 1ms ease 0s;
}
.css-62g3xt-dummyInput {
    background: 0px center;
    border: 0px;
    font-size: inherit;
    outline: 0px;
    padding: 0px;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    transform: scale(0);
}
.css-snhw4r {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-top: 0px;
}
.css-1veywqn-indicatorContainer {
    display: flex;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
    padding: 6px 2px;
    color: var(--ds-icon-selected,#FFFFFF) !important;
}
.gxwVbe {
    display: flex;
}
.bxpmxB > * {
    display: block;
    margin: 4px;
    min-width: 0px;
}
.jnXgrr {
    position: relative;
}
.css-1f7f0z2 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: var(--ds-background-neutral, rgba(9, 30, 66, 0.04));
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 10px;
    vertical-align: middle;
    width: auto;
    -webkit-box-pack: center;
    justify-content: center;
    color: var(--ds-text, #42526E) !important;
}
.css-178ag6o {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    margin: 0px 2px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.iitdmc {
    padding-right: 16px;
}
.kJxPgr {
    background: 0px center;
    border: 0px;
    height: 24px;
    line-height: 1;
    outline: 0px;
    padding: 0px;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -12px;
    width: 24px;
}
.jpQXKX {
    margin-top: 24px;
}
.bETBem {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    /* margin-top: 136px; */
}
.bETBem > img {
    width: 232px;
}
.RIerR {
    text-align: center;
    padding: 0px 16px;
    /* margin-top: 48px; */
    max-width: 304px;
}
.RIerR > p {
    margin-top: 16px;
}
.gUQSWG {
    padding-top: 0px;
    padding-bottom: 0px;
}
.bokdCL {
    padding-top: 0px;
    padding-bottom: 0px;
}
.css-qezc8k {
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    /* background-color: var(--ds-background-neutral, #DFE1E6); */
    border-radius: 3px;
    overflow: hidden;
}
.css-18kwa17 {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-subtle, #42526E);
    font-size: var(--ds-font-size-050, 15px);
    font-weight: var(--ds-font-weight-bold, 600);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}

.disabled_3757 {
    pointer-events: none;
    opacity: 0.5;
  }



