.ewjKEg {
    padding: var(--ds-space-300,24px) var(--ds-space-400,32px);
}
.ewjKEg {
    display: flex;
    width: 100%;
    /*height: 100%;*/
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    /*padding: var(--ds-space-200,16px) var(--ds-space-300,24px);*/
    padding-top: 45px;
}
.ldzcRq {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
}
.sUzaF {
    color: var(--ds-text-brand,#f37237);
}
.sUzaF {
    font-size: 1.5em;
    font-style: inherit;
    /*line-height: 1.25;*/
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,400);
    letter-spacing: -0.006em;
    margin-top: 0px;
    margin-bottom: var(--ds-space-100,8px);
    /*max-height: 20px;*/
}

.cYVick {
    font-size: 14px;
    line-height: 20px;
    max-height: 60px;
}

.cYVick {
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 54px;
    overflow: hidden;
    max-width: 100%;
    color: var(--ds-text,#172B4D);
    line-height: 18px;
}
.fPTfed::after {
    border-radius: var(--ds-border-radius-100,3px);
    content: "";
    position: absolute;
    inset: 0px;
    transition: opacity 140ms ease-in-out 0s;
    box-shadow: var(--ds-shadow-overlay,0 4px 8px -2px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
    opacity: 0;
    z-index: inherit;
}

.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}
.cJOWBq {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: var(--ds-space-200,16px) 20px;
}
.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}

.fPTfed {
    height: 144px;
    width: 336px;
}
.fPTfed {
    border-radius: var(--ds-border-radius-100,10px);
    box-sizing: border-box;
    display: block;
    height: 200px;
    width: 350px;
    z-index: 0;
    position: relative;
    background-color: var(--ds-surface-raised,#FFFFFF);
    transition: transform 140ms ease-in-out 0s;
    background-color: #FFFFFF;
}
.fPTfed::before {
    border-radius: var(--ds-border-radius-100,3px);
    content: "";
    position: absolute;
    inset: 0px;
    transition: opacity 140ms ease-in-out 0s;
    box-shadow: var(--ds-shadow-raised,0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13));
    z-index: inherit;
}

.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}
.kGJshR {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: auto;
}
.ljcORE {
    padding-top: 0px;
    padding-bottom: 0px;
}
.sAatJ.sidebar-closed {
    /*max-width: 336px;*/
}

.sAatJ {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--ds-space-100,8px) var(--ds-space-100,8px) var(--ds-space-600,48px) var(--ds-space-100,8px);
    align-self: center;
    width: 100%;
}
.jlVbwK {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: var(--ds-space-300,24px);
}
.jnQxTY {
    font-size: 1.14286em;
    font-style: inherit;
    line-height: 1.25;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.006em;
    align-self: flex-start;
    box-sizing: border-box;
    /*margin-top: ;
    margin-right: ;
    margin-bottom: ;*/
    margin-left: 36px;
}
.fUWJNj {
    display: flex;
    flex-flow: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px -40px;
}
.body
{
   font-family: Arial;
  margin: 0;
  padding: 0;
  
    background-color:#4b9fc4;
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
a:focus-visible {
    outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684FF);
    outline-offset: var(--ds-space-025, 2px);
}
.fPTfed:hover, .fPTfed:focus {
    z-index: 0;
    text-decoration: none;
}

.fPTfed:focus, .fPTfed:hover {
    transform: translate3d(0px, -5px, 5px);
}
a:focus, a:hover, a:active {
    text-decoration: underline;
}
.fPTfed:focus:not(.focus-visible) {
    outline: 0px;
}
.fPTfed:hover, .fPTfed:focus {
    z-index: 0;
    text-decoration: none;
}

.fPTfed:focus, .fPTfed:hover {
    transform: translate3d(0px, -5px, 5px);
}
