.igIKoG {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    height: 56px;
    overflow: hidden;
}
.gNaqGG {
    color: rgb(255, 255, 255);
}
.kcCKhl {
    height: 40px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 8px;
    font-weight: 600;
}
.PlFKx {
    height: 40px;
}
.cXAxUP {
    line-height: 0;
}
.hRNIvE {
    margin: 0px;
    padding: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.hfyJKb {
    display: inline;
    margin: 0px;
}
.fYOBLE {
    padding-right: 8px;
}
.eRRDfp {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 1;
}
.Wndrw {
    background-color: var(--ds-surface-overlay,#EBECF0);
    transition: background-color 450ms ease 0s, transform 199.8ms ease 0s;
    transform: none;
}
.Wndrw::after {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    background-color: var(--ds-surface-overlay-hovered,rgba(9,30,66,0.60));
    opacity: 0;
    transition: opacity 200ms ease 0s;
    pointer-events: none;
    box-sizing: border-box;
}
.busxTh {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent;
    color: var(--ds-text,initial);
    border: none;
    cursor: pointer;
    z-index: 3;
    padding: 0px;
    opacity: 1;
    transition: opacity 50ms ease 149.8ms;
    border-radius: 50%;
    box-sizing: border-box;
}
.hmocgh {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    inset: 0px;
}
.eaUPvS span > span, .eaUPvS svg {
    height: 20px;
    width: 20px;
}
.css-1afrefi {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    --icon-primary-color: var(--ds-icon, #42526E);
    --icon-secondary-color: var(--ds-surface, #FFFFFF);
    
}
.css-1afrefi > svg {
    width: 24px;
    height: 24px;
}
.css-1afrefi > svg {
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
}
.hfyJKb {
    display: inline;
    margin: 0px;
}
.eTmqqm {
    padding: 0px;
}
.cLrmQm {
    display: inline-flex;
}
.eteVrT {
    display: inline-flex;
    transition: box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}
.kOqTiO {
    cursor: pointer;
    background-color: rgb(243, 114, 55);
    padding: 0px 12px;
    border: 2px var(--ds-border-inverse,#FFFFFF) solid;
    border-radius: 20px;
    font-size: 14px;
    height: 36px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to right, rgb(243, 114, 55), rgb(243, 114, 55));
    transition: background-color 50ms ease 0s, transform 50ms ease 0s;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
button {
    font-family: inherit;
}
.jrYKHX {
    margin-bottom: 1px;
}
.kOqTiO::after {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 20px;
    background-color: var(--ds-interaction-hovered,rgba(9,30,66,0.60));
    opacity: 0;
    transition: opacity 200ms ease 0s;
    pointer-events: none;
    box-sizing: border-box;
}
.cXwmnx {
    display: none;
}
.hfyJKb {
    display: inline;
    margin: 0px;
}
.fHzrwK {
    padding: 0px 8px;
    height: 36px;
}
.cLrmQm {
    display: inline-flex;
}
.eteVrT {
    display: inline-flex;
    transition: box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
}
.gIogYT {
    border-radius: 50%;
    display: flex;
    overflow: visible;
    position: relative;
}
.css-nr1tyk {
    height: 32px;
    width: 32px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-border-inverse, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-border-inverse, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
}
.css-13ep12v {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 100%;
}
.eJvVfu {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 352px;
    z-index: -2;
    background-color: #1565C0;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.fgKECP {
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
    box-sizing: border-box;
    overflow: auto;
    padding: 4px 0px;
    max-height: 90vh;
}
.pdDLy {
    max-width: 300px;
}
.jbXTXO {
    -webkit-box-align: center;
    align-items: center;
    color: rgb(107, 119, 140);
    display: flex;
    flex: 1 1 auto;
    padding: 8px 12px;
}
.gjxsxF {
    flex: 1 1 auto;
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase;
    min-width: 0px;
}
.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}

.jivWnY:focus, .jivWnY:hover, .jivWnY:visited, .jivWnY:link, .jivWnY:active {
    text-decoration: none;
}

.jivWnY {
    text-decoration: none;
}
.eJTYOk.eJTYOk {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    fill: rgb(255, 255, 255);
    text-decoration: none;
    padding: 8px 12px 7px;
    width: auto;
}
.eDgbRC:first-child {
    margin: 0px;
}

.eDgbRC {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0px;
    overflow: hidden;
}

.eJTYOk.eJTYOk {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    fill: rgb(255, 255, 255);
    text-decoration: none;
    padding: 8px 12px 7px;
    width: auto;
}
.jRBaLt {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.14286;
    padding-bottom: 1px;
}

.eJTYOk.eJTYOk {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    fill: rgb(255, 255, 255);
    text-decoration: none;
    padding: 8px 12px 7px;
    width: auto;
}