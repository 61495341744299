.w3-light-grey, .w3-hover-light-grey:hover, .w3-light-gray, .w3-hover-light-gray:hover {
    color: #000!important;
    background-color: #f1f1f1!important;
}
.w3-green, .w3-hover-green:hover {
    color: #fff!important;
    background-color: #4CAF50!important;
}
.body
{
   font-family: Arial;
  margin: 0;
  padding: 0;
  
    background-color:#4b9fc4;
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.login{
        width: 500px;
        overflow: hidden;
        /* margin: 20 0 0 450px; */
        padding: 80px;
        background: #ffffff;
        border-radius: 15px ;
        margin: auto;
}

label{
    color:#010a0e87;
    font-size: 17px;
}
#Uname{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;
}
#Mobile{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;

}
#confirm{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;

}
#Email{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;

}
#type{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;

}
#Pass{
    width: 300px;
    height: 30px;
    /*border: none;
    border-radius: 3px;*/
    padding-left: 8px;

}
#log{
    height: 42px;
    width: 300px;
    background-color: #00638f;
    border-radius: 17px;
    padding-left: 7px;
   color: #fff;
}


.a{
    float: right;
    /*background-color: grey;*/
}
.jNMwMZ {
    margin-left: var(--ds-space-negative-200,-16px);
}
.css-1undbr0 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 1.71429em;
    line-height: 1.71429em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(52, 39, 235) !important;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-8xpfx5 {
    align-self: center;
    display: flex;
    flex-shrink: 0;
    line-height: 0;
    font-size: 0px;
    user-select: none;
    margin: 0px 4px;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-mu6jxl {
    -webkit-box-align: baseline;
    align-items: baseline;
    align-self: baseline;
    flex: 1 1 auto;
    margin: 0px 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-1undbr0:hover
{
    text-decoration:none;
    cursor: pointer;
}
.SignIn-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-bottom: 20px;
}
.SignIn-header h4{
    font-size: 20px;
    font-weight: 600;

}
.forgot-password{
    display: flex;
    justify-content: center;
    align-items: center;
}
.error-popup {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ff0000;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #ff0000;
    font-size: 14px;
    top: calc(100% + 5px); /* Position right below the input field */
    left: 0;
  }
  
  .error-popup::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #ff0000 transparent transparent;
    top: -6px;
    left: calc(50% - 6px);
  }
  