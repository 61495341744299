.card {
    border: 2px solid black; /* Border color */
    border-radius: 25px; /* Curved border ends */
    margin-left: 20px; /* Margin between cards */
    margin-right: 10px; /* Margin between cards */
    padding: 20px; /* Increase padding for larger cards */
    background-color: #f8f9fa; /* Background color */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Box shadow for 3D effect */
    transition: 0.3s; /* Smooth hover effect */
  }

  /* Increase font size for card content */
  .card-title,
  .card-text {
    font-size: 20px; /* Increase font size for larger text */
  }

  /* Hover effect for cards */
  .card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }

  .support-dashboard-outer1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;

  }