.header{
    background-color: #03007F;
}
.terms-sec{
    background-color: #e5e5e5;
    
}

/* Body *//* Body */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e5e5e5;
}

/* Main Content */
.container {
    max-width: 960px;
    margin: 20px auto;
    padding: 0 15px;
}

.col-sm-12 {
    padding: 15px;
}

/* Terms Content */
dd {
    line-height: 1.6;
    margin-left: 20px; /* Add margin from the left */
}

dd > strong {
    margin-left: 0; /* Exclude margin for headlines */
}

dd p {
    margin-left: 10px; /* Adjust margin for paragraphs within <dd> */
}

/* Footer */

b, strong {
    font-weight: bolder;
    /* margin-left: 50px; */
}
user agent stylesheet
/* Add padding-left to list items */
li {
    margin-left: 100px; /* Adjust as needed */
  }
  
  /* Style the marker */
  li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
    /* Add left margin to the marker */
    margin-left: 10px; /* Adjust as needed */
  }
  .header .navbar .brand {
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 12px 0;
    text-align: center;
}
img {
    vertical-align: middle;
    border-style: none;
    
}