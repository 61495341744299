.hvAxLV {
    margin-top: 24px;
}

  
.gDXBCU {
    overflow-x: auto;
    padding: 2px 2px 20px;
    height: 500px;
}
.zdDVp {
    min-width: 800px;
}
.css-h8zv0n {
    margin-bottom: var(--ds-space-300, 24px);
    position: relative;
}
p:first-child, ul:first-child, ol:first-child, dl:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, blockquote:first-child, pre:first-child, form:first-child, table:first-child {
    margin-top: 0;
}

.S4 {
    background-color: #99ccff;
  }
  
  .S3 {
    background-color: #66a3ff;
  }
  
  .S2 {
    background-color: #3366ff;
  }
  .S1 {
    background-color: #000080;
  }
  .label {
    border-radius: 20px;
    padding: 5px;
    color: white;
    margin-bottom: 10px;
    width: 70px;
    text-align: center;
   }

.css-fkqt3q {
    width: 100%;
    /*border-collapse: separate;*/
    border-spacing: 0px;
    table-layout: fixed;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    --local-dynamic-table-hover-bg: var(--ds-background-neutral-subtle-hovered, #FAFBFC);
    --local-dynamic-table-highlighted-bg: var(--ds-background-selected, #DEEBFF);
    --local-dynamic-table-hover-highlighted-bg: var(--ds-background-selected-hovered, #B3D4FF);
    --local-dynamic-table-row-focus-outline: var(--ds-border-focused, #4C9AFF);
}
.css-1g54gxt {
    border-bottom: none;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}.css-1xabuij:first-of-type {
    padding-left: var(--ds-space-0, 0px);
}

td:first-child, th:first-child {
    padding-left: 0;
}

.css-1xabuij {
    --local-dynamic-table-width: 0.5%;
    --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    box-sizing: border-box;
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    color: var(--ds-text-subtlest, var(--local-dynamic-table-text-color));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    vertical-align: top;
    width: var(--local-dynamic-table-width);
}

table {
    border-collapse: collapse;
    width: 100%;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
    margin: 12px 0 0 0;
}
.css-100m01t {
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    box-sizing: border-box;
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    color: var(--ds-text-subtlest, var(--local-dynamic-table-text-color));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    vertical-align: top;
    width: var(--local-dynamic-table-width);
    --local-dynamic-table-width: 0.9%;
    --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);
}
.css-100m01t > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.css-100m01t > button::before, .css-100m01t > button::after {
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    right: -8px;
    border: 3px solid transparent;
    content: "";
}

.css-100m01t > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}

button {
    font-family: inherit;
}
.css-100m01t > button::before {
    bottom: 8px;
    border-bottom: 3px solid var(--ds-icon-disabled, #DFE1E6);
}
.css-100m01t > button::after {
    border-top: 3px solid var(--ds-icon-subtle, #5E6C84);
}

.css-100m01t > button::after {
    bottom: 0px;
    border-top: 3px solid var(--ds-icon-disabled, #DFE1E6);
}

.css-100m01t > button::before, .css-100m01t > button::after {
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    right: -8px;
    border: 3px solid transparent;
    content: "";
}

.css-100m01t > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}
button {
    font-family: inherit;
}
.css-1xabuij {
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    box-sizing: border-box;
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    color: var(--ds-text-subtlest, var(--local-dynamic-table-text-color));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    vertical-align: top;
    width: var(--local-dynamic-table-width);
    --local-dynamic-table-width: 1.5%;
    --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);
}
.css-1xabuij {
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    box-sizing: border-box;
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    color: var(--ds-text-subtlest, var(--local-dynamic-table-text-color));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    vertical-align: top;
    width: var(--local-dynamic-table-width);
    --local-dynamic-table-width: 1%;
    --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);
}
.css-11phftz:last-child {
    padding-right: var(--ds-space-0, 0px);
}

td:last-child, th:last-child {
    padding-right: 0;
}

.css-11phftz {
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    box-sizing: border-box;
    position: relative;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
    color: var(--ds-text-subtlest, var(--local-dynamic-table-text-color));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    vertical-align: top;
    width: var(--local-dynamic-table-width);
    --local-dynamic-table-width: 0.9%;
    --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);
}
.css-11phftz > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.css-11phftz > button::before {
    bottom: 8px;
    border-bottom: 3px solid var(--ds-icon-disabled, #DFE1E6);
}

.css-11phftz > button::before, .css-11phftz > button::after {
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    right: -8px;
    border: 3px solid transparent;
    content: "";
}

.css-11phftz > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.css-11phftz > button::after {
    bottom: 0px;
    border-top: 3px solid var(--ds-icon-disabled, #DFE1E6);
}

.css-11phftz > button::before, .css-11phftz > button::after {
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    right: -8px;
    border: 3px solid transparent;
    content: "";
}

.css-11phftz > button {
    padding: var(--ds-space-0, 0);
    position: relative;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.css-zu8jlf {
    width: var(--local-dynamic-table-width);
    overflow: hidden;
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    border: none;
    text-align: center;
}
thead, tbody, tfoot {
    border-bottom: 2px solid var(--ds-border, #DFE1E6);
}
.css-unmje5 {
    background-color: var(--ds-background-neutral-subtle, transparent);
}
.css-zu8jlf:first-of-type {
    padding-left: var(--ds-space-0, 0px);
}
.eKBzuN {
    width: 24px;
    height: 24px;
}

td:first-child, th:first-child {
    padding-left: 0;
}

.css-zu8jlf {
    width: var(--local-dynamic-table-width);
    overflow: hidden;
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    border: none;
    text-align: center;
    --local-dynamic-table-width: 0.5%;
}
.AlQuL {
    color: var(--ds-text-subtle,#6B778C);
    overflow-wrap: break-word;
}
.css-zu8jlf {
    width: var(--local-dynamic-table-width);
    overflow: hidden;
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    border: none;
    text-align: center;
    --local-dynamic-table-width: 1.5%;
}
.gjGtwC {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    
}
.css-zu8jlf {
    width: var(--local-dynamic-table-width);
    overflow: hidden;
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    border: none;
    text-align: center;
    --local-dynamic-table-width: 1%;
}
.css-cztx6i-opened {
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success,#fdf0f0 );
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-cztx6i-Closed{
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success, #E3FCEF);
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-cztx6i-Resolved{
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success, #f9e9fe);
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-cztx6i-Rejected{
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success, #fef1ed);
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-cztx6i-InProgress{
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success, #ebedfe);
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-cztx6i-opened {
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    background-color: var(--ds-background-success,#fce9e9 );
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}
.css-1et8jlg-Closed {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-success, #006644);
    font-size: var(--ds-font-size-050, 11px);
    font-weight: var(--ds-font-weight-bold, 700);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    max-width: calc(200px - var(--ds-space-100, 8px));
}
.css-1et8jlg-opened {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-success, #f72020);
    font-size: var(--ds-font-size-050, 11px);
    font-weight: var(--ds-font-weight-bold, 700);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    max-width: calc(200px - var(--ds-space-100, 8px));
}
.css-1et8jlg-Rejected {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-success, #f75620);
    font-size: var(--ds-font-size-050, 11px);
    font-weight: var(--ds-font-weight-bold, 700);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    max-width: calc(200px - var(--ds-space-100, 8px));
}
.css-1et8jlg-Resolved {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-success, #9620f7);
    font-size: var(--ds-font-size-050, 11px);
    font-weight: var(--ds-font-weight-bold, 700);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    max-width: calc(200px - var(--ds-space-100, 8px));
}
.css-1et8jlg-InProgress {
    box-sizing: border-box;
    margin: var(--ds-space-0, 0px);
    padding: var(--ds-space-0, 0px);
    font-family: var(--ds-font-family-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
    color: var(--ds-text-success, #2059f7);
    font-size: var(--ds-font-size-050, 11px);
    font-weight: var(--ds-font-weight-bold, 700);
    line-height: var(--ds-font-lineHeight-100, 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    max-width: calc(200px - var(--ds-space-100, 8px));
}
.css-zu8jlf:last-child {
    padding-right: var(--ds-space-0, 0px);
}

td:last-child, th:last-child {
    padding-right: 0;
}

.css-zu8jlf {
    width: var(--local-dynamic-table-width);
    overflow: hidden;
    padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
    border: none;
    text-align: center;
    --local-dynamic-table-width: 0.9%;
}
.gjGtwC {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}


