.dWMRNy {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}
.ixIiAw > * {
    margin-bottom: var(--ds-space-050,4px);
}
.efarfX {
    border-radius: var(--ds-border-radius-100,3px);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 32px;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.hARvOT {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.css-1m1hxa9 {
    height: 24px;
    width: 24px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.cAbcUG {
    font-size: 11px;
    color: var(--ds-text-subtlest,#6B778C);
    margin-top: 0px;
}
.cAbcUG {
    font-size: 11px;
    color: var(--ds-text-subtlest,#6B778C);
    margin-top: 0px;
}
.css-1vqao0l {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}
.css-8qwk8x {
    align-self: center;
    display: flex;
    flex-shrink: 0;
    line-height: 0;
    font-size: 0px;
    user-select: none;
    margin: 0px;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.hEbrcv {
    padding: var(--ds-space-025,2px);
}
.css-snhnyn {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
}
.css-1h9rjj2 {
    -webkit-box-align: baseline;
    align-items: baseline;
    align-self: baseline;
    flex: 1 1 auto;
    margin: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.bvvtkm {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    margin-left: var(--ds-space-100,8px);
}
.kzucLN {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.css-1ip0m3v {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: auto;
    line-height: inherit;
    padding: 0px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: baseline;
    width: 100%;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
.css-1ip0m3v:hover {
    text-decoration: inherit;
    background: rgba(9, 30, 66, 0.08);
}
.fKobQG {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}
.css-vxcmzt {
    display: inline-flex;
}
.css-vxcmzt {
    display: inline-flex;
}
.css-vxcmzt {
    display: inline-flex;
}
.css-68ar9j {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: var(--ds-text-inverse,#FFFFFF) !important;
}
.css-z25nd1 {
    flex: 1 0 auto;
    display: flex;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-t5emrf {
    -webkit-box-align: center;
    align-items: center;
    align-self: center;
    flex: 1 1 auto;
    margin: 0px 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-1c1jbkd-control {
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: rgb(244, 245, 247);
    border-color: rgb(223, 225, 230);
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
    box-sizing: border-box;
    padding: 0px;
    width: 350px;
    height: 100%;
    max-width: 100%;
    outline: 0px !important;
}
.css-5a7vsu-container {
    pointer-events: all;
    position: relative;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.mQHAl {
    height: inherit;
    top: 0px;
}

.mQHAl {
    position: absolute;
    width: 1000px;
    height: inherit;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 50%;
    /*border-top-left-radius: ;
    border-top-right-radius: ;*/
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: var(--ds-surface,#FFFFFF);
    box-shadow: var(--ds-shadow-overlay,0 var(--ds-space-100,8px) var(--ds-space-200,16px) -4px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
    z-index: -1;
    transform: translate3d(-50%, 0px, 0px) scale3d(1, 1, 1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.dKLBoM {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: var(--ds-space-0,0px) auto;
    width: 100%;
    max-width: 1000px;
    min-height: inherit;
}
.klGEsD {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.dvFdfp {
    margin-right: 0px;
}

.dvFdfp {
    flex: 1 1 auto;
    max-width: 504px;
    margin-right: var(--ds-space-600,48px);
    margin-top: 36px;
}
.gdaJLx {
    border-color: var(--ds-border,#EBECF0);
    border-radius: var(--ds-border-radius-100,3px);
    border-width: var(--ds-border-width,1px);
    border-style: solid;
    padding: var(--ds-space-200,16px);
}
.jOaoYz {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.css-1m1hxa9 {
    height: 24px;
    width: 24px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.css-13ep12v {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 100%;
}
.css-1m1hxa9::after {
    background-color: transparent;
    inset: 0px;
    border-radius: 50%;
    content: " ";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 200ms ease 0s;
    width: 100%;
}
.yANRH {
    margin-left: var(--ds-space-100,8px);
}
.css-puqikf {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(243, 114, 55) !important;
}
.css-puqikv {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(243, 114, 55) !important;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-t5emrf {
    -webkit-box-align: center;
    align-items: center;
    align-self: center;
    flex: 1 1 auto;
    margin: 0px 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.kMDvmM {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: 16px;
}
.CyzLZ {
    margin-top: var(--ds-space-100,8px);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}
.css-2c6ch1 {
    position: relative;
    width: 100%;
}
.css-1jset9w {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
.css-1jset9w p {
    font-size: 1em;
    line-height: 1.714;
    font-weight: normal;
    margin-top: 0.75rem;
    margin-bottom: 0px;
    letter-spacing: -0.005em;
}
.css-1jset9w .ak-renderer-document::after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0px;
    clear: both;
}

.css-1jset9w {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.CyzLZ {
    margin-top: var(--ds-space-100,8px);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}
.hKEIRt {
    margin-top: var(--ds-space-300,24px);
}

.fWuoyP {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: 16px;
}

.fWuoyP {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: 16px;
}

.keVOrZ {
    margin-top: var(--ds-space-200,16px);
    margin-bottom: var(--ds-space-300,24px);
}
html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
    margin: 0;
    padding: 0;
}
.cHtPAi > * {
    margin-top: var(--ds-space-200,16px);
}


.GHUyr {
    margin-top: var(--ds-space-200,16px);
}
.hUwXrK:first-child, .hUwXrK:first-of-type {
    padding-top: 0px;
}

.scroll-menus {
   width: 100px;
   border-radius: 10%;
   overflow: auto;
   font-size: 14px;
   cursor: 'pointer';
   /* border:  solid #ccc; */
   margin-left: 120px;
}
.menu-items {
    margin: 5px 0;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 5px;
    cursor: pointer;
}


  .menu-items:hover {
    background-color: #1976d2;;
  }

  .select-container-scroll{
    /* position: relative; */
  display: inline-block;
  }
.hUwXrK {
    display: grid;
    grid-template:
        "avatar-area comment-area" auto
        ". nested-comments-area" auto / auto 1fr;
    padding-top: 28px;
    position: relative;
}
.hvNajw {
    grid-area: avatar-area / avatar-area / avatar-area / avatar-area;
    margin-right: 8px;
}
.css-1qdk52b {
    height: 32px;
    width: 32px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.css-1qdk52b {
    height: 32px;
    width: 32px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.fErUzI {
    grid-area: comment-area / comment-area / comment-area / comment-area;
    margin-top: 2px;
    min-width: 0px;
    overflow-wrap: break-word;
}
.dChGZQ {
    display: flex;
}
.cjZbUi:first-child {
    margin-left: 0px;
}
.ekKeSG:not(:hover):not(:active) {
    color: rgb(66, 82, 110);
}
.cjZbUi {
    display: inline-block;
    margin-left: 8px;
}
.jLNzsu:not(:hover):not(:active) {
    color: rgb(66, 82, 110);
}

.jLNzsu {
    font-weight: inherit;
}

.fErUzI {
    grid-area: comment-area / comment-area / comment-area / comment-area;
    margin-top: 2px;
    min-width: 0px;
    overflow-wrap: break-word;
}
.kuiQOK {
    color: rgb(23, 43, 77);
    margin-top: 4px;
}
.irmqVw {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
}
.cHtPAi > * {
    margin-top: var(--ds-space-200,16px);
}
.hUwXrK:first-child, .hUwXrK:first-of-type {
    padding-top: 0px;
}


.hUwXrK {
    display: grid;
    grid-template:
        "avatar-area comment-area" auto
        ". nested-comments-area" auto / auto 1fr;
    padding-top: 28px;
    position: relative;
}

.hvNajw {
    grid-area: avatar-area / avatar-area / avatar-area / avatar-area;
    margin-right: 8px;
}
.css-1qdk52b {
    height: 32px;
    width: 32px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.css-1qdk52b {
    height: 32px;
    width: 32px;
    -webkit-box-align: stretch;
    align-items: stretch;
    background-color: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 50%;
    box-sizing: content-box;
    cursor: inherit;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    overflow: hidden;
    position: static;
    transform: translateZ(0px);
    transition: transform 200ms ease 0s, opacity 200ms ease 0s;
    box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
    border: none;
    margin: var(--ds-space-025, 2px);
    padding: var(--ds-space-0, 0px);
    font-size: inherit;
    font-family: inherit;
}
.fErUzI {
    grid-area: comment-area / comment-area / comment-area / comment-area;
    margin-top: 2px;
    min-width: 0px;
    overflow-wrap: break-word;
}
.dChGZQ {
    display: flex;
}
.cjZbUi:first-child {
    margin-left: 0px;
}

.cjZbUi {
    display: inline-block;
    margin-left: 8px;
}
.ekKeSG:not(:hover):not(:active) {
    color: rgb(66, 82, 110);
}


.ekKeSG {
    font-weight: 500;
}

.fErUzI {
    grid-area: comment-area / comment-area / comment-area / comment-area;
    margin-top: 2px;
    min-width: 0px;
    overflow-wrap: break-word;
}
.cjZbUi {
    display: inline-block;
    margin-left: 8px;
}

html, body, p, div, h1, h2, h3, h4, h5, h6, img, pre, form, fieldset {
    margin: 0;
    padding: 0;
}

html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
    margin: 0;
    padding: 0;
}
.fErUzI {
    grid-area: comment-area / comment-area / comment-area / comment-area;
    margin-top: 2px;
    min-width: 0px;
    overflow-wrap: break-word;
}
.jLNzsu:not(:hover):not(:active) {
    color: rgb(66, 82, 110);
}


.jLNzsu {
    font-weight: inherit;
}

.kuiQOK {
    color: rgb(23, 43, 77);
    margin-top: 4px;
}
.css-2c6ch1 {
    position: relative;
    width: 100%;
}
.css-1jset9w {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
.css-1jset9w p {
    font-size: 1em;
    line-height: 1.714;
    font-weight: normal;
    margin-top: 0.75rem;
    margin-bottom: 0px;
    letter-spacing: -0.005em;
}

.react-tagsinput{
    width: 158px;
    border-radius: 4px;
}
.css-1jset9w {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.css-1jset9w .ak-renderer-document::after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0px;
    clear: both;
}

.css-1jset9w {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
.irmqVw {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
}
.SBKnj {
    width: 100%;
    max-width: 252px;
    /* margin-top: 36px; */
  

    /* margin-right: var(--ds-space-600,48px); */
}
.Description-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center-align text */
    margin-bottom: 20px; /* Add margin-bottom */
    margin-left: 100px;
}
.request-detailsx{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Ensures items start from the same vertical position */
    margin: 10px;
    border-radius: 4px;
    /* align-items: center; */
      }
      
      
      .request-detailsx > * {
        flex: 1;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      .request-detailsx.dropdown-container {
        position: relative;
      }
      
      .request-detailsx.dropdown-container .status-container {
        cursor: pointer;
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .request-detailsx.dropdown-container .dropdown {
        position: absolute;
        top: 100%; /* Position the dropdown directly below the status */
        left: 0;
        width: 130px; /* Make the dropdown full width */
        display: flex;
        margin-left: 100px;
        flex-direction: column;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* z-index: 1; */
text-align: left;
    }
    .request-detailsx.dropdown-container .dropdown > div {
        /* padding: unset; */
        cursor: pointer;
        transition: background-color 0.3s;
        border-bottom: 1px solid #ccc; /* Add a bottom border */
      }
      
      .request-detailsx.dropdown-container .dropdown > div:last-child {
        border-bottom: none; /* Remove the bottom border for the last option */
      }
      
      .request-detailsx.dropdown-container .dropdown > div:hover {
        background-color: #f0f0f0;
        transition: background-color 0.3s;
        cursor: pointer;
        /* padding: 8px; */


      }
      /* .request-detailsx.dropdown-container .dropdown > div {
        padding: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
      } */
      
      .request-detailsx.dropdown-container .dropdown > div:hover {
        background-color: #f0f0f0;
      }
      
.hzWGel {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    
    margin-bottom: var(--ds-space-100,8px);
    margin-top: 40px;
  
}
.css-cztx6i {
    box-sizing: border-box;
    appearance: none;
    border: none;
    display: inline-flex;
    position: static;
    padding-inline: var(--ds-space-050, 4px);
    /*background-color: var(--ds-background-success, #E3FCEF);*/
    border-radius: 3px;
    overflow: hidden;
}
.hzWGel {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: var(--ds-space-300,24px);
    margin-bottom: var(--ds-space-100,8px);
}
.bPQdOV {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.cpDveY {
    margin-left: var(--ds-space-100,8px);
}
.hzWGel {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: var(--ds-space-300,24px);
    margin-bottom: var(--ds-space-100,8px);
}
.fWalNV > * {
    margin-bottom: var(--ds-space-050,4px);
}
.cOwNxZ {
    margin-top: var(--ds-space-400,32px);
}
.dhbBCH {
    display: flex;
    flex-direction: row;
    gap:100px;
    justify-content: center;
    /* flex-wrap: wrap; */
    /* -webkit-box-pack: justify; */
    /* justify-content: space-between; */
}

.hXhZlB {
    margin-right: 0px;
}

.hXhZlB {
    flex: 1 1 auto;
    max-width: 450px;
    /* margin-right: var(--ds-space-600,48px); */
   
}
.gwKMCV {
    width: 100%;
    margin-top: 5px;
    min-height: 120px;
    padding: 12px;
    color: rgb(55, 53, 47);
    caret-color: rgb(55, 53, 47);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
    border: 0px;
    border-radius: 5px;
    outline: none;
}
.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect */
  .button:hover {
    background-color: #2980b9;
  }
  
  /* Active (click) effect */
  .button:active {
    background-color: #1f618d;
  }
  .ljPvSw {
    border-color: var(--ds-border,#EBECF0);
    border-radius: var(--ds-border-radius-100,3px);
    border-width: var(--ds-border-width,1px);
    border-style: solid;
    padding: var(--ds-space-200,16px);
}
.eZXad {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.ejZygw {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.dWOhZl {
    margin-left: var(--ds-space-100,8px);
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}


.jhHSQz {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0px;
    position: absolute;
    height: 1px;
    margin: -1px;
    width: 1px;
    white-space: nowrap;
}
.jhHSQy {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0px;
    position: absolute;
    height: 1px;
    margin: -1px;
    width: 1px;
    white-space: nowrap;
}
.eDqiDP {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    margin-top: 16px;
}
.gYVzTh {
    margin-top: var(--ds-space-100,8px);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}
.css-t3wrjx {
    font-size: 14px;
    line-height: 1.5rem;
    color: var(--ds-text, #172B4D);
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
.css-t3wrjx p {
    font-size: 1em;
    line-height: 1.714;
    font-weight: normal;
    margin-top: 0.75rem;
    margin-bottom: 0px;
    letter-spacing: -0.005em;
}
.css-4oxim9 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(243, 114, 55) !important;
}
.css-4oxin9 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(243, 114, 55) !important;
}
.css-4oxim9:hover
{
    text-decoration: underline;
}
.css-4oxin9:hover
{
    text-decoration: underline;
}
.css-puqikf:hover {
    text-decoration: underline;
}
.css-puqikv:hover {
    text-decoration: underline;
}

.arrow-container {
    display: flex;
    align-items: center;
    
  }
  .share-img-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    }
.share-img-container img{
    width: 20px;
    height:18px;
    margin-left:220px;

}
.description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    margin: 10px;
    margin-bottom: 20px;
    border: 1px solid black; /* Black border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow */
    border-radius: 4px;
    border: 0.5px solid black; /* Reduced border size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .description-title {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    color: var(--ds-text, var(--ds-text, #172B4D));
    font-weight: var(--ds-font-weight-semibold, 600);
    letter-spacing: -0.003em;
    margin-top: var(--ds-space-300, 24px);
    margin-bottom: var(--ds-space-100, 8px);
    margin-top:20px;
    align-items: center;
margin: 0;  }
  
  .arrow-container {
    display: flex;
    align-items: center;
  }
  
  .arrow-btn {
    margin-left: 8px; /* Adjust the margin as needed */
    cursor: pointer;
    color: blue; /* Set the color of the arrow */
    font-size: 1.2em; /* Adjust the font size as needed */
  
  }
  .arrow-char {
    color: blue; /* Set the color of the arrow character */
  }
  .up-arrow {
    font-size: 24px;
  }
  .down-arrow {
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
  }
  .inprogress-btn{
    margin-top: 10px;
  }
  .dropdown-container
  {
    background-color: "red";
    /* z-index: 1; */
  }

  .disabled-div123 {
    pointer-events: none; /* Disables all click and touch events */
    /* opacity: 0.5;  */
    /* Optionally, you can also reduce the opacity to visually indicate that it's disabled */
}