.scroll-menu {
 
  width: auto;
  max-height: 300px;
  min-height: 30px;
  height: auto;

  overflow-y: auto;
  border: 1px solid #1976d2;
  padding: 2px;
  /* border-radius: 10px; */
  z-index: 1;
  position: absolute;
  background-color: white;
  color: black;
  margin-top: 30px;;
}
.scroll-menu::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
 
.scroll-menu::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px;
}
 
.scroll-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}
 
.scroll-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
 
.menu-item {
    /* margin: 5px 0; */
    padding: 5px;
    background-color: #f4f4f4;
    /* border-radius: 5px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px
}


  .menu-item:hover {
    background-color: #1976d2;
    .css-18kwa17 {

    color:white
    }
  }
.birthday-class{
  height:32px;
  margin-left: 5px;
  
}



.select-wrapper {
  position: relative;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000; /* Ensure the options appear above other content */
}
