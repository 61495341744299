footer {
    background-color:rgb(19 105 203);
    color: #fff;
    padding: 8px; /* Reduce padding to decrease height */
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 5px; */
    margin-top: 15px;
  }
 
  .left-content,
  .right-content {
    flex: 1;
 
  }
  .left-content {
    padding-right: 250px; /* Add space from the left side */
  }
 
  .right-content {
    padding-right: 100px; /* Add space from the right side */
  }
 
  .left-content p,
  .right-content p {
    margin: 0;
    font-size: 10px;
    color:white /* Adjust font size to fit within the reduced height */
  }
  .right-content {
    text-align: right; /* Align text to the right */
  }
  .right-content a{
    color:white
  }
  .right-content a {
    text-decoration: none;
    color: white;
    transition: border-bottom 0.3s ease, color 0.3s ease; /* Add color transition */
}

.right-content a:hover {
    color: white; /* Change to a color that contrasts with white */
    border-bottom: 1px solid white; 
}