*{
    margin: 0;
    padding: 0;
}
.ratea {
    float: left;
    height: 80px;
    /*padding: 0 10px;
      padding-left: 40px;*/
    padding-right: 50px;
}
.ratea:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.ratea:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:55px;
    color:#ccc;
}
.ratea:not(:checked) > label:before {
    content: '★ ';
}
.ratea > input:checked ~ label {
    color: #ffc700;    
}
.ratea:not(:checked) > label:hover,
.ratea:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.ratea > input:checked + label:hover,
.ratea > input:checked + label:hover ~ label,
.ratea > input:checked ~ label:hover,
.ratea > input:checked ~ label:hover ~ label,
.ratea > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.login {
    width: 500px;
    overflow: hidden;
    margin: auto;
    margin: 20 0 0 450px;
    padding: 50px;
    background: #ffffff;
    border-radius: 15px;
}
.gwKMCV {
    width: 100%;
    min-height: 96px;
    padding: 12px;
    color: rgb(55, 53, 47);
    caret-color: rgb(55, 53, 47);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
    border: 0px;
    border-radius: 5px;
    outline: none;
}
.hGQpYF svg:last-child {
    margin-left: 10px;
}
.hGQpYF svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
}
.hGQpYF {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    height: 32px;
    padding: 0px 12px;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background: rgb(0, 0, 0);
    outline: none;
    transition: color 100ms ease-in 0s;
    text-align: left;
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.enIueZ {
    display: inline-flex;
}

.rateb {
    float: left;
    height: 80px;
    padding: 0 10px;
    padding-left: 40px;
    padding-right: 50px;
}
.rateb:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rateb:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:50px;
    color:#ccc;
}
.rateb:not(:checked) > label:before {
    content: '★ ';
}
.rateb > input:checked ~ label {
    color: #ffc700;    
}
.rateb:not(:checked) > label:hover,
.rateb:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rateb > input:checked + label:hover,
.rateb > input:checked + label:hover ~ label,
.rateb > input:checked ~ label:hover,
.rateb > input:checked ~ label:hover ~ label,
.rateb > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.ratec {
    float: left;
    height: 90px;
    padding: 0 10px;
    padding-left: 40px;
    padding-right: 50px;
}
.ratec:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.ratec:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:50px;
    color:#ccc;
}
.ratec:not(:checked) > label:before {
    content: '★ ';
}
.ratec > input:checked ~ label {
    color: #ffc700;    
}
.ratec:not(:checked) > label:hover,
.ratec:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.ratec > input:checked + label:hover,
.ratec > input:checked + label:hover ~ label,
.ratec > input:checked ~ label:hover,
.ratec > input:checked ~ label:hover ~ label,
.ratec > label:hover ~ input:checked ~ label {
    color: #c59b08;
}