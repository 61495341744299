.kOqTiO {
    cursor: pointer;
    background-color: rgb(243, 114, 55);
    padding: 0px 12px;
    border: 2px var(--ds-border-inverse,#FFFFFF) solid;
    border-radius: 20px;
    font-size: 14px;
    height: 36px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to right, rgb(243, 114, 55), rgb(243, 114, 55));
    transition: background-color 50ms ease 0s, transform 50ms ease 0s;
    position: relative;
    display: flex;
    align-items: center;
}
 
  
.kOqTiO.hovered {
    background-color: #e74c3c; /* Change the background color when hovered */
     cursor: pointer;
    background-color: rgb(243, 114, 55);
    padding: 0px 12px;

    border: 2px var(--ds-border-inverse,#FFFFFF) solid;
    border-radius: 20px;
    font-size: 14px;
    height: 36px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to right, rgb(243, 114, 55), rgb(243, 114, 55));
    transition: background-color 50ms ease 0s, transform 50ms ease 0s;
    position: relative;
    display: flex;
    align-items: center;
  }