#dialog-box {
    display: inline;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    text-align: center;

}
#dialog-header {
    background-color:#007bff;
;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#dialog-close {
    font-size: 20px;
    cursor: pointer;
}
#dialog-body {
    padding: 20px;
}
#dialog-footer {
    padding: 10px;
    text-align: center;
}
#save-dialog {
    background-color:white; /* White background */
    color: #007bff; /* Blue text color */
    border: 1px solid #007bff; /* Blue border */
    padding: 7px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */

}

#save-dialog:hover {
    background-color: #0056b3; /* Light gray background on hover */
    color:white ;
}
#close-dialog {
    background-color: #007bff; /* Blue color */
    color: #fff; /* White text color */
    border: 1px solid #007bff; /* Blue border */
    padding: 7px 20px;
    cursor: pointer;
    transition: "background-color 0.3s ease,color 0.3s ease";
}

#close-dialog:hover {
    background-color:  #0056b3;; /* Light gray background on hover */
}


#close-dialog,
#save-dialog {
    margin: 10px;
}


.cesPGE {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    height: auto;
}
.fayCzS {
    overflow-wrap: break-word;
}
.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}
.dNSoSr {
    color: var(--ds-text,#172B4D);
    padding: 0px 8px;
}

.dUxdnV {
    margin-top: 8px;
}
.dUxdnV {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 16px;
}
.OGFXn {
    height: 50px;
    width: 50px;
}

.OGFXn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    margin-right: 16px;
    flex-shrink: 0;
}
.bGtLRv {
    font-size: 1.71429em;
    font-style: inherit;
    line-height: 1.16667;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-medium,500);
    letter-spacing: -0.01em;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0px;
}
.kOAttI {
    display: block;
    color: var(--ds-text,#172B4D);
    margin-top: 8px;
    width: 100%;
}
.bokdCL {
    padding-top: 0px;
    padding-bottom: 0px;
}
.eIvNgR {
    margin: 32px 0px 8px;
    position: relative;
}
.bfEIYs {
    font-size: 0.857143em;
    font-style: inherit;
    line-height: 1.33333;
    color: var(--ds-text-subtlest,var(--ds-text-subtlest,#6B778C));
    font-weight: var(--ds-font-weight-semibold,600);
    margin-top: 16px;
    margin-bottom: 4px;
}
.hyLwHu {
    font-size: 1.14286em;
    font-style: inherit;
    line-height: 1.25;
    color: var(--ds-text,var(--ds-text,#172B4D));
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.006em;
    margin-top: 24px;
    display: block;
    margin-bottom: 16px;
}
.css-5a7vsu-container {
    pointer-events: all;
    position: relative;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.css-bzqzs4-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--ds-background-accent-gray-subtlest,#F4F5F7);
    border-color: var(--ds-background-accent-gray-subtlest,#F4F5F7);
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
    box-sizing: border-box;
    padding: 0px;
    outline: 0px !important;
}
.css-s88hxx {
    display: flex;
    overflow: hidden;
}
.css-8atqhb {
    width: 100%;
}
.gRnkOb {
    margin-left: 8px;
}
.css-62g3xt-dummyInput {
    background: 0px center;
    border: 0px;
    font-size: inherit;
    outline: 0px;
    padding: 0px;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    transform: scale(0);
}
.css-t5ibhw {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 4px;
}
.css-gg6ksl-indicatorContainer {
    color: rgb(66, 82, 110);
    display: flex;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
    padding: 6px 2px;
}
.css-snhnyn {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    --icon-primary-color: currentColor;
    --icon-secondary-color: var(--ds-surface, #FFFFFF);
}
.css-snhnyn > svg {
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
}
.gOgtpf {
    margin-top: 32px;
}
.leXqIt {
    margin: 0px;
    padding: 0px;
}
.kjKnbo {
    display: block;
}
.kVwhHK a:not(.cv-exclude-from-customisation), .kVwhHK a:hover:not(.cv-exclude-from-customisation) {
    color: var(--ds-link,#f37237);
}
.gxlgvd {
    padding: 24px;
}
.gyFiyb {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 32px;
    align-self: flex-start;
    margin-top: 4px;
    flex-shrink: 0;
    margin-right: 16px;
}
.fDacxp {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.isCMef {
    width: 100%;
    overflow: hidden;
}
.eQofGC {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    font-weight: var(--ds-font-weight-semibold,600);
    letter-spacing: -0.003em;
    color: var(--ds-text-brand,#f37237);
    margin: 0px;
}
.piyAY {
    color: var(--ds-text,#091E42);
    margin-top: 4px;
}
.gLHWRW {
    position: relative;
    min-height: 400px;
}
.fLqQEk {
    transition: opacity 300ms ease-out 0ms;
    opacity: 1;
}
.css-1qzu3fk {
    font-size: 0.857143em;
    font-style: inherit;
    line-height: 1.33333;
    font-weight: var(--ds-font-weight-semibold, 600);
    color: var(--ds-text-subtle, var(--ds-text-subtlest, #6B778C));
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 4px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.kHmppP {
    padding-left: 2px;
    color: var(--ds-text-danger,#DE350B);
}
.css-1o07z2d {
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--ds-background-input, #FAFBFC);
    border-color: var(--ds-border-input, #DFE1E6);
    color: var(--ds-text, #091E42);
    cursor: text;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    font-size: 14px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    overflow-wrap: break-word;
    vertical-align: top;
    pointer-events: auto;
}
.css-mfjdp3:not([data-compact]) {
    padding: 8px 6px;
    height: 2.57em;
}

.css-mfjdp3 {
    background-color: transparent;
    border: 0px;
    box-sizing: border-box;
    color: inherit;
    cursor: inherit;
    font-size: 14px;
    min-width: 0px;
    outline: none;
    width: 100%;
    line-height: 1.42857;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.css-1dr2qy6 {
    position: relative;
    width: 65%;
    height: 100%;
}
.hcyXVm .akEditor {
    border-radius: var(--ds-border-radius-100,3px);
    border-width: 2px;
    border-style: solid;
    border-image: initial;
    border-color: var(--ds-border-input,#DFE1E6);
    background-color: var(--ds-background-input,#FAFBFC);
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
}
.css-18w41as > div {
    animation: auto ease 0s 1 normal none running none;
}
.hcyXVm .akEditor > div {
    background-color: var(--ds-background-input,#FAFBFC);
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
}
.css-1atvvg6 {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    /*padding-top: ;
    padding-right: ;
    padding-bottom: ;*/
    display: flex;
    height: auto;
    background-color: var(--ds-surface, white);
    box-shadow: none;
    padding-left: var(--ds-space-250, 20px);
}
.css-1yk94i7 {
    display: flex;
    flex-direction: column;
    min-width: 738px;
    height: auto;
    background-color: var(--ds-background-input, white);
    border: 1px solid var(--ds-border, #DFE1E6);
    box-sizing: border-box;
    border-radius: 3px;
    max-width: inherit;
    overflow-wrap: break-word;
    min-height: 150px;
}
.css-yd211k {
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
}
.css-1atvvg6 > div > :first-child:not(style), .css-1atvvg6 > div > style:first-child + * {
    margin-left: 0px;
}
.css-1ky3qeu {
    width: 100%;
    position: relative;
    min-width: 254px;
}
.css-6gmvcx {
    display: flex;
}
.css-2i865c {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 0px;
    min-width: auto;
}
.css-2i865c > div, .css-2i865c > span {
    display: flex;
}
.css-2i865c > div > div {
    display: flex;
}
.css-13cz8p3 {
    display: flex;
    height: 100%;
}
.css-1atvvg6 .block-type-btn {
    padding-left: 0px;
}
.css-pg7w17 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: var(--ds-background-neutral-subtle, none);
    cursor: pointer;
    height: auto;
    line-height: inherit;
    padding: 0px;
    vertical-align: baseline;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    color: var(--ds-text, #42526E) !important;
}
.css-1gd7hga {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    margin: 0px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.css-nim8xe {
    display: flex;
    min-width: 80px;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--ds-space-100, 8px);
}
.css-l2xrfb {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    display: flex;
    margin: 0px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    font-size: 0px;
    line-height: 0;
    user-select: none;
}
.css-1cm0fqf {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 0px;
    min-width: auto;
}
.css-1cm0fqf > div, .css-1cm0fqf > span {
    display: flex;
}
.css-il1drd {
    margin-left: -8px;
}
.css-snhnyn {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
}
.css-2i865c > div, .css-2i865c > span {
    display: flex;
}

.css-g4dhky {
    background: var(--ds-border, #EBECF0);
    width: 1px;
    height: 24px;
    display: inline-block;
    margin: 0 var(--ds-space-100, 8px);
    user-select: none;
}
.css-16cy0zh {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding-right: var(--ds-space-250, 20px);
}
.hcyXVm .akEditor > div {
    background-color: var(--ds-background-input,#FAFBFC);
    transition: background-color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
}
.css-1q3xxv2 {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 100%;
}
.css-ov1ktg {
    display: flex;
}
.eotBHb .akEditor .less-margin .ProseMirror {
    margin: 12px 14px 14px;
}
.css-1ijlzqt .ProseMirror {
    margin: var(--ds-space-150, 12px) 14px 14px;
}

.css-1ijlzqt .ProseMirror {
    outline: none;
    font-size: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
.css-1qzu3fk {
    font-size: 0.857143em;
    font-style: inherit;
    line-height: 1.33333;
    font-weight: var(--ds-font-weight-semibold, 600);
    color: var(--ds-text-subtle, var(--ds-text-subtlest, #6B778C));
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 4px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.fkZtIa {
    border-radius: var(--ds-border-radius-100,3px);
    border: 2px dashed var(--ds-border-input,#F4F5F7);
}
.gJJhIj {
    padding: 16px;
    text-align: center;
}
.evXuov {
    margin-bottom: 8px;
}
.gJJhIj {
    padding: 16px;
    text-align: center;
}
.css-18u3ks8 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-t5emrf {
    -webkit-box-align: center;
    align-items: center;
    align-self: center;
    flex: 1 1 auto;
    margin: 0px 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.icDrbe {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 16px;
    -webkit-box-pack: start;
    justify-content: flex-start;
}
.css-68ar9j {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: rgb(39, 3, 246);
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: var(--ds-text-inverse,#FFFFFF) !important;
}

.icDrbe > * {
    margin-right: 8px;
}

button {
    font-family: inherit;
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ReactModal__Content {
    width: 300px;
    max-height: 200px;
    background-color: #fff; /* White background */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    color: #333; /* Dark color for better visibility */
  }
  
  /* label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold; 
  } */
  
  /* input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box; 
  } */
  
  .add_but {
    background: rgb(39, 3, 246);    color: #fff; 
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 100px;
  } 

   .share_but{
    margin-top: 5px;
    margin-left: 45px;
} 

.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.css-t5emrf {
    -webkit-box-align: center;
    align-items: center;
    align-self: center;
    flex: 1 1 auto;
    margin: 0px 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
}
.css-q68uj {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    cursor: default;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 8px;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    width: auto;
    outline: none !important;
    color: rgb(66, 82, 110) !important;
}

.icDrbe > * {
    margin-right: 8px;
}

button {
    font-family: inherit;
}
.css-j8fq0c {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
}
.gUQSWG {
    padding-top: 0px;
    padding-bottom: 0px;
}
.dWMRNy {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}
.jmFfsW {
    height: inherit;
    top: 0px;
}
.jmFfsW {
    position: absolute;
    width: 1000px;
    height: inherit;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 50%;
    /*border-top-left-radius: ;
    border-top-right-radius: ;*/
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: var(--ds-surface,#FFFFFF);
    box-shadow: var(--ds-shadow-overlay,0 var(--ds-space-100,8px) 16px -4px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
    z-index: -1;
    transform: translate3d(-50%, 0px, 0px) scale3d(0.8, 1, 1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.bJStMD {
    transition: opacity 300ms ease-out 300ms;
    opacity: 1;
}
.fpdUcT {
    min-height: inherit;
    margin-top: 0px;
}

.fpdUcT {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: var(--ds-space-0,0px) auto;
    width: 100%;
    max-width: 800px;
    min-height: inherit;
}
.scroll-menu {
    width: auto;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
  }

  .css-ox6esc {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}
.css-ox6esc > div {
    display: flex;
}
.css-13cz8p3 {
    display: flex;
    height: 100%;
}
.css-1muxtt6 {
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: var(--ds-border-radius, 3px);
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: var(--ds-background-neutral-subtle, none);
    cursor: pointer;
    height: auto;
    line-height: inherit;
    padding: 0px;
    vertical-align: baseline;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    color: var(--ds-text, #42526E) !important;
}
.css-18kgcs9 {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    display: flex;
    margin: 0px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    font-size: 0px;
    line-height: 0;
    user-select: none;
}
.css-snhnyn {
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    --icon-primary-color: currentColor;
    --icon-secondary-color: var(--ds-surface, #FFFFFF);
}
.css-snhnyn > svg {
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

#myOutline{
    padding: 5px;
    margin: 5px 0;
    outline: none;
    border-color: transparent;
    border-bottom: 2px solid #1c87c9;
  }


  .gSlDUV {
    position: absolute;
    width: 240px;
    height: 340px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: white;
    top: 90px;
    right: 40px;
    z-index: 5;
}
.ixchdn {
    height: 340px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.jplYCh {
    font-size: 18px;
    margin: 50px auto;
}
.gSlDUV .sc-gLDzan, .gSlDUV .sc-iAEyYk, .gSlDUV .sc-fsQiph {
    margin-top: auto;
}
  /* CSS */
.button-12 {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
  
    background: #6E6D70;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
    color: #DFDEDF;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-12:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }